import { EventIds } from 'constants/eventIds';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, ApolloError } from '@apollo/client';
import { PackBySlugOrGuid } from 'apollo/pack/pack.gql';
import { getSanitizeSlug } from 'helpers/getSanitizeSlug';
import {
    PackBySlugOrGuidQuery,
    PackBySlugOrGuidQueryVariables,
} from 'types/generated/graphql';
import { log } from 'utils/log';
import { DynamicMetadata } from '../DynamicMetadata/DynamicMetadata';
import { getPackSeoFallback } from './helpers/getPackSeoFallback';

export const usePackDetails = () => {
    const { slug } = useParams<{ slug: string }>();
    const { data } = useQuery<
        PackBySlugOrGuidQuery,
        PackBySlugOrGuidQueryVariables
    >(PackBySlugOrGuid, {
        variables: { packSlugOrGuid: getSanitizeSlug(slug) },
        onError: (error: ApolloError) => {
            log.error(
                'Failed to get pack by slug',
                EventIds.GetPackFailed,
                error,
                { slug: slug },
            );
        },
    });

    if (!data?.pack?.name) {
        return {};
    }

    const { name, artworkUrl, artist, isFree } = data.pack;

    const { titleFallback, descriptionFallback } = getPackSeoFallback({
        artistName: artist?.name,
        isFree,
        packName: name,
    });

    return {
        title: titleFallback,
        description: descriptionFallback,
        imageUrl: artworkUrl,
    };
};

export const PackDetails = () => {
    const { title, description, imageUrl } = usePackDetails();

    if (!title || !description) {
        return null;
    }

    return (
        <DynamicMetadata
            imageUrl={imageUrl}
            titleFallback={title}
            descriptionFallback={description}
        />
    );
};
