import { gql } from '@apollo/client';
import { ARTIST_EXCERPT_FRAGMENT } from 'apollo/artist/artist.fragment';
import { GENRE_FRAGMENT, SFX_FRAGMENT } from 'apollo/filters/filters.fragment';
import { LABEL_EXCERPT_FRAGMENT } from 'apollo/label/label.fragment';

export const PACK_EXCERPT_FRAGMENT = gql`
    fragment PackExcerptFragment on Pack {
        artworkUrl
        guid
        isDownloaded
        isExclusive
        isFavorited
        isFree
        name
        packState
        slug
        type
        artist {
            ...ArtistExcerptFragment
        }
        label {
            ...LabelExcerptFragment
        }
    }
    ${ARTIST_EXCERPT_FRAGMENT}
    ${LABEL_EXCERPT_FRAGMENT}
`;

export const PACK_DETAIL_FRAGMENT = gql`
    fragment PackDetailFragment on Pack {
        ...PackExcerptFragment
        backgroundColor
        creatorSession
        description
        foregroundColor
        premiumCodes
        sampleCount
        genres {
            ...GenreFragment
        }
        mainGenre {
            ...GenreFragment
        }
        sfxs {
            ...SfxFragment
        }
    }
    ${PACK_EXCERPT_FRAGMENT}
    ${GENRE_FRAGMENT}
    ${SFX_FRAGMENT}
`;
