// Brandtype can be added via the content admin
// BE do not return us the list of all brandty
export enum BrandtypeSlug {
    ArtistPicks = 'artist-picks',
    BeatStarters = 'beat - starters',
    Creator = 'creator',
    DrumKits = 'drum-kits',
    DrumRacks = 'drum-racks',
    Free = 'free',
    LandrSelect = 'landr-select',
    Lofi = 'lofi',
    PremiumPacks = 'premium-packs',
    QuickPicks = 'quick-picks',
    Sfx = 'sfx',
    TopSamples = 'top-samples',
    Trap = 'trap',
    TypeBeats = 'type-beats',
}
