import { t } from '@lingui/macro';

export const getPackSeoFallback = ({
    packName,
    artistName,
    isFree,
}: {
    packName: string;
    artistName?: string;
    isFree: boolean;
}) => {
    const isFreeWithArtistName = isFree && !!artistName;
    const isFreeWithoutArtistName = isFree && !artistName;
    const isPaidWithArtistName = !isFree && !!artistName;
    const isPaidWithoutArtistName = !isFree && !artistName;

    let title: string | undefined;
    let description: string | undefined;

    switch (true) {
        case isFreeWithArtistName:
            title = t({
                message: '{packName} - {artistName} Free Sample Pack',
                values: { packName, artistName },
            });
            description = t({
                message:
                    'Download {packName} - {artistName} free sample pack from LANDR Samples. Find the best free sample packs, loops, synths, vocals, drum kits and royalty free sound libraries to inspire your music production.',
                values: { packName, artistName },
            });
            break;

        case isFreeWithoutArtistName:
            title = t({
                message: '{packName} Free Sample Pack',
                values: { packName },
            });
            description = t({
                message:
                    'Download {packName} free sample pack from LANDR Samples. Find the best free sample packs, loops, synths, vocals, drum kits and royalty free sound libraries to inspire your music production.',
                values: { packName },
            });
            break;

        case isPaidWithArtistName:
            title = t({
                message: '{packName} - {artistName} Sample Pack',
                values: { packName, artistName },
            });
            description = t({
                message:
                    'Download {packName} - {artistName} sample pack from LANDR Samples. Get the best royalty free sample packs, loops, synths, vocals and drum kits from LANDR Samples.',
                values: { packName, artistName },
            });
            break;

        case isPaidWithoutArtistName:
            title = t({
                message: '{packName} Sample Pack',
                values: { packName },
            });
            description = t({
                message:
                    'Download {packName} sample pack from LANDR Samples. Get the best royalty free sample packs, loops, synths, vocals and drum kits from LANDR Samples.',
                values: { packName },
            });
            break;
    }

    return {
        titleFallback: title,
        descriptionFallback: description,
    };
};
