import { BrandtypeSlug } from 'constants/brandtypeSlug';
import { t } from '@lingui/macro';

// Default
const seoFallbackDefaultTitle = (repackName: string) =>
    t({
        message: '{repackName} - Sample Pack',
        values: { repackName },
    });
const seoFallbackDefaultDescription = ({
    repackName,
    firstGenre,
    secondGenre,
}: {
    repackName: string;
    firstGenre?: string;
    secondGenre?: string;
}) => {
    if (firstGenre && secondGenre) {
        return t({
            message:
                'Download {repackName}, a sample pack from LANDR Samples. Find the best royalty-free {firstGenre} samples, {secondGenre} loops, one-shots, drum kits and more on LANDR Samples.',
            values: { repackName, firstGenre, secondGenre },
        });
    } else if (firstGenre) {
        return t({
            message:
                'Download {repackName}, a sample pack from LANDR Samples. Find the best royalty-free {firstGenre} samples, loops, one-shots, drum kits and more on LANDR Samples.',
            values: { repackName, firstGenre },
        });
    }

    return t({
        message:
            'Download {repackName}, a sample pack from LANDR Samples. Find the best royalty-free samples, loops, one-shots, drum kits and more on LANDR Samples.',
        values: { repackName },
    });
};

// ArtistPicks
const seoFallbackArtistPicksDescription = (repackName: string) =>
    t({
        message:
            '{repackName} Samples – custom built sample packs, one shots, beats, percussions, and more — for you to download and use in your creative projects.',
        values: { repackName },
    });

// Beat Starters
const seoFallbackBeatStartersTitle = (repackName: string) =>
    t({
        message: '{repackName} - Sample Pack - Construction Kits',
        values: { repackName },
    });
const seoFallbackBeatStartersDescription = ({
    repackName,
    firstGenre,
}: {
    repackName: string;
    firstGenre?: string;
}) => {
    if (firstGenre) {
        return t({
            message:
                'Download the {repackName} sample pack on LANDR Samples. A {firstGenre} construction kit to inspire you when starting a new beat. Royalty-free samples, loops, one-shots, drum kits and more.',
            values: { repackName, firstGenre },
        });
    }

    return t({
        message:
            'Download the {repackName} sample pack on LANDR Samples. A construction kit to inspire you when starting a new beat. Royalty-free samples, loops, one-shots, drum kits and more.',
        values: { repackName, firstGenre },
    });
};

// Creator Packs
const seoFallbackCreatorDescription = (repackName: string) =>
    t({
        message:
            'Get inspired by the {repackName} Creator kit. Creator sample packs are made to inspire your music creations. Add up to 8 loops, adjust key and BPM and layer sounds to sketch out your next track.',
        values: { repackName },
    });

// Drum Kits
const seoFallbackDrumKitsTitle = (repackName: string) =>
    t({
        message: '{repackName} Samples',
        values: { repackName },
    });

const seoFallbackDrumKitsDescription = (repackName: string) =>
    t({
        message:
            '{repackName} samples – drums, bass, 808s, percussion, kicks, clap, hi-hat and more — for you to download and use in your productions.',
        values: { repackName },
    });

// Drum Racks
const seoFallbackDrumRacksDescription = (repackName: string) =>
    t({
        message:
            '{repackName} Samples – custom built sample packs one shots, beats, percussions, and more — for you to download and use in your creative projects.',
        values: { repackName },
    });

// Free
const seoFallbackFreeTitle = (repackName: string) =>
    t({
        message: 'The {repackName} - Sample Pack',
        values: { repackName },
    });
const seoFallbackFreeDescription = ({
    repackName,
    firstGenre,
    secondGenre,
}: {
    repackName: string;
    firstGenre?: string;
    secondGenre?: string;
}) => {
    if (firstGenre && secondGenre) {
        return t({
            message:
                'Download the {repackName} sample pack from LANDR Samples. Find the best royalty-free {firstGenre} samples, {secondGenre} loops, one-shots, drum kits and more on LANDR Samples.',
            values: { repackName, firstGenre, secondGenre },
        });
    } else if (firstGenre) {
        return t({
            message:
                'Download the {repackName} sample pack from LANDR Samples. Find the best royalty-free {firstGenre} samples, loops, one-shots, drum kits and more on LANDR Samples.',
            values: { repackName, firstGenre },
        });
    }

    return t({
        message:
            'Download the {repackName} sample pack from LANDR Samples. Find the best royalty-free samples, loops, one-shots, drum kits and more on LANDR Samples.',
        values: { repackName },
    });
};

// LANDR Select
const seoFallbackLandrSelectDescription = ({
    repackName,
    firstGenre,
}: {
    repackName: string;
    firstGenre?: string;
}) => {
    if (firstGenre) {
        return t({
            message:
                'Download {repackName}, a {firstGenre} sample pack from LANDR Samples. Find the best royalty-free samples, loops, vocals, one-shots, drum kits and more on LANDR Samples.',
            values: { repackName, firstGenre },
        });
    }

    return t({
        message:
            'Download {repackName}, a sample pack from LANDR Samples. Find the best royalty-free samples, loops, vocals, one-shots, drum kits and more on LANDR Samples.',
        values: { repackName },
    });
};

// Lofi
const seoFallbackLofiDescription = ({
    repackName,
    firstGenre,
    instrument,
}: {
    repackName: string;
    firstGenre?: string;
    instrument?: string;
}) => {
    if (firstGenre && instrument) {
        return t({
            message:
                'Download {repackName}, a lofi sample pack from LANDR Samples. Find the best royalty-free {firstGenre} samples, {instrument} loops, one-shots, drum kits and more on LANDR Samples.',
            values: { repackName, firstGenre, instrument },
        });
    } else if (firstGenre) {
        return t({
            message:
                'Download {repackName}, a lofi sample pack from LANDR Samples. Find the best royalty-free {firstGenre} samples, loops, one-shots, drum kits and more on LANDR Samples.',
            values: { repackName, firstGenre },
        });
    } else if (instrument) {
        return t({
            message:
                'Download {repackName}, a lofi sample pack from LANDR Samples. Find the best royalty-free samples, {instrument} loops, one-shots, drum kits and more on LANDR Samples.',
            values: { repackName, instrument },
        });
    }

    return t({
        message:
            'Download {repackName}, a lofi sample pack from LANDR Samples. Find the best royalty-free samples, loops, one-shots, drum kits and more on LANDR Samples.',
        values: { repackName },
    });
};

// Premium Packs
const seoFallbackPremiumPacksTitle = (repackName: string) =>
    t({
        message: '{repackName} Samples - Sample Pack',
        values: { repackName },
    });
const seoFallbackPremiumPacksDescription = ({
    repackName,
    instrument,
}: {
    repackName: string;
    instrument?: string;
}) => {
    if (instrument) {
        return t({
            message:
                '{repackName} samples – premium {instrument} samples, one shots, loops, vocals, drum kits and more — for you to download and use in your productions.',
            values: { repackName, instrument },
        });
    }

    return t({
        message:
            '{repackName} samples – premium samples, one shots, loops, vocals, drum kits and more — for you to download and use in your productions.',
        values: { repackName },
    });
};

// Quick Picks
const seoFallbackQuickPicksTitle = (repackName: string) =>
    t({
        message: 'Royalty Free {repackName} Samples',
        values: { repackName },
    });
const seoFallbackQuickPicksDescription = ({
    repackName,
    firstGenre,
    instrument,
}: {
    repackName: string;
    firstGenre?: string;
    instrument?: string;
}) => {
    if (firstGenre && instrument) {
        return t({
            message:
                '{repackName} samples – {instrument} samples, {firstGenre} loops, one shots, phrases, melodies, chords, licks and more — for you to download and use in your productions.',
            values: { repackName, instrument, firstGenre },
        });
    } else if (firstGenre) {
        return t({
            message:
                '{repackName} samples – {firstGenre} loops, one shots, phrases, melodies, chords, licks and more — for you to download and use in your productions.',
            values: { repackName, firstGenre },
        });
    } else if (instrument) {
        return t({
            message:
                '{repackName} samples – {instrument} samples, loops, one shots, phrases, melodies, chords, licks and more — for you to download and use in your productions.',
            values: { repackName, instrument },
        });
    }

    return t({
        message:
            '{repackName} samples – loops, one shots, phrases, melodies, chords, licks and more — for you to download and use in your productions.',
        values: { repackName },
    });
};

// SFX
const seoFallbackSfxTitle = (repackName: string) =>
    t({
        message: '{repackName} Sound Effect Sample Pack',
        values: { repackName },
    });

const seoFallbackSfxDescription = ({
    repackName,
    instrument,
}: {
    repackName: string;
    instrument?: string;
}) => {
    if (instrument) {
        return t({
            message:
                'Download the {repackName} sound effect sample pack from LANDR Samples. Easy to use, royalty free {instrument} samples for your creative projects.',
            values: { repackName, instrument },
        });
    }

    return t({
        message:
            'Download the {repackName} sound effect sample pack from LANDR Samples. Easy to use, royalty free samples for your creative projects.',
        values: { repackName },
    });
};

// Top Samples
const seoFallbackTopSamplesTitle = (repackName: string) =>
    t({
        message: '{repackName} - Top Charts',
        values: { repackName },
    });
const seoFallbackTopSamplesDescription = ({
    repackName,
    firstGenre,
    instrument,
}: {
    repackName: string;
    firstGenre?: string;
    instrument?: string;
}) => {
    if (firstGenre && instrument) {
        return t({
            message: `Download the {repackName} sample pack containing today's best {firstGenre} loops, vocals, drum kits & {instrument} samples.`,
            values: { repackName, firstGenre, instrument },
        });
    } else if (firstGenre) {
        return t({
            message: `Download the {repackName} sample pack containing today's best {firstGenre} loops, vocals, drum kits & samples.`,
            values: { repackName, firstGenre },
        });
    } else if (instrument) {
        return t({
            message: `Download the {repackName} sample pack containing today's best loops, vocals, drum kits & {instrument} samples.`,
            values: { repackName, instrument },
        });
    }

    return t({
        message: `Download the {repackName} sample pack containing today's best loops, vocals, drum kits & samples.`,
        values: { repackName },
    });
};
// Trap
const seoFallbackTrapDescription = ({
    repackName,
    firstGenre,
    instrument,
}: {
    repackName: string;
    firstGenre?: string;
    instrument?: string;
}) => {
    if (firstGenre && instrument) {
        return t({
            message:
                'Download {repackName}, a trap sample pack from LANDR Samples. Find the best royalty-free {firstGenre} samples, {instrument} loops, one-shots, drum kits and more on LANDR Samples.',
            values: { repackName, firstGenre, instrument },
        });
    } else if (firstGenre) {
        return t({
            message:
                'Download {repackName}, a trap sample pack from LANDR Samples. Find the best royalty-free {firstGenre} samples, loops, one-shots, drum kits and more on LANDR Samples.',
            values: { repackName, firstGenre },
        });
    } else if (instrument) {
        return t({
            message:
                'Download {repackName}, a trap sample pack from LANDR Samples. Find the best royalty-free samples, {instrument} loops, one-shots, drum kits and more on LANDR Samples.',
            values: { repackName, instrument },
        });
    }

    return t({
        message:
            'Download {repackName}, a trap sample pack from LANDR Samples. Find the best royalty-free samples, loops, one-shots, drum kits and more on LANDR Samples.',
        values: { repackName },
    });
};
// Type Beats
const seoFallbackTypeBeatsDescription = ({
    repackName,
    firstGenre,
    secondGenre,
}: {
    repackName: string;
    firstGenre?: string;
    secondGenre?: string;
}) => {
    if (firstGenre && secondGenre) {
        return t({
            message:
                '{repackName} samples – Royalty free {firstGenre} samples, {secondGenre} beats, loops & vocals in the style of {repackName} — for you to download and use in your productions.',
            values: { repackName, firstGenre, secondGenre },
        });
    } else if (firstGenre) {
        return t({
            message:
                '{repackName} samples – Royalty free {firstGenre} samples, beats, loops & vocals in the style of {repackName} — for you to download and use in your productions.',
            values: { repackName, firstGenre },
        });
    }

    return t({
        message:
            '{repackName} samples – Royalty free samples, beats, loops & vocals in the style of {repackName} — for you to download and use in your productions.',
        values: { repackName },
    });
};

export const getRePackSeoFallback = ({
    brandtypeSlug,
    repackName,
    firstGenre,
    secondGenre,
    instrument,
}: {
    brandtypeSlug?: string;
    repackName: string;
    firstGenre?: string;
    secondGenre?: string;
    instrument?: string;
}): {
    title: string;
    description: string;
} => {
    switch (brandtypeSlug) {
        case BrandtypeSlug.ArtistPicks:
            return {
                title: seoFallbackDefaultTitle(repackName),
                description: seoFallbackArtistPicksDescription(repackName),
            };
        case BrandtypeSlug.BeatStarters:
            return {
                title: seoFallbackBeatStartersTitle(repackName),
                description: seoFallbackBeatStartersDescription({
                    repackName,
                    firstGenre,
                }),
            };

        case BrandtypeSlug.Creator:
            return {
                title: seoFallbackDefaultTitle(repackName),
                description: seoFallbackCreatorDescription(repackName),
            };
        case BrandtypeSlug.DrumKits:
            return {
                title: seoFallbackDrumKitsTitle(repackName),
                description: seoFallbackDrumKitsDescription(repackName),
            };

        case BrandtypeSlug.DrumRacks:
            return {
                title: seoFallbackDefaultTitle(repackName),
                description: seoFallbackDrumRacksDescription(repackName),
            };

        case BrandtypeSlug.Free:
            return {
                title: seoFallbackFreeTitle(repackName),
                description: seoFallbackFreeDescription({
                    repackName,
                    firstGenre,
                    secondGenre,
                }),
            };
        case BrandtypeSlug.LandrSelect:
            return {
                title: seoFallbackDefaultTitle(repackName),
                description: seoFallbackLandrSelectDescription({
                    repackName,
                    firstGenre,
                }),
            };

        case BrandtypeSlug.Lofi:
            return {
                title: seoFallbackDefaultTitle(repackName),
                description: seoFallbackLofiDescription({
                    repackName,
                    firstGenre,
                    instrument,
                }),
            };

        case BrandtypeSlug.PremiumPacks:
            return {
                title: seoFallbackPremiumPacksTitle(repackName),
                description: seoFallbackPremiumPacksDescription({
                    repackName,
                    instrument,
                }),
            };

        case BrandtypeSlug.QuickPicks:
            return {
                title: seoFallbackQuickPicksTitle(repackName),
                description: seoFallbackQuickPicksDescription({
                    repackName,
                    firstGenre,
                    instrument,
                }),
            };

        case BrandtypeSlug.Sfx:
            return {
                title: seoFallbackSfxTitle(repackName),
                description: seoFallbackSfxDescription({
                    repackName,
                    instrument,
                }),
            };

        case BrandtypeSlug.TopSamples:
            return {
                title: seoFallbackTopSamplesTitle(repackName),
                description: seoFallbackTopSamplesDescription({
                    repackName,
                    firstGenre,
                    instrument,
                }),
            };

        case BrandtypeSlug.Trap:
            return {
                title: seoFallbackDefaultTitle(repackName),
                description: seoFallbackTrapDescription({
                    repackName,
                    firstGenre,
                    instrument,
                }),
            };
        case BrandtypeSlug.TypeBeats:
            return {
                title: seoFallbackDefaultTitle(repackName),
                description: seoFallbackTypeBeatsDescription({
                    repackName,
                    firstGenre,
                    secondGenre,
                }),
            };
        default:
            return {
                title: seoFallbackDefaultTitle(repackName),
                description: seoFallbackDefaultDescription({
                    repackName,
                    firstGenre,
                    secondGenre,
                }),
            };
    }
};
