import getStaticSeo from 'constants/staticSeo';
import { useSeoMetaData } from 'hooks';
import { BrowseSeoType } from 'types/seoType';
import { RoutePaths } from 'types/enums';
import { useMatch } from 'react-router-dom';
import { useToLocalizedPath } from 'hooks/useToLocalizedPath/useToLocalizedPath';
import { t } from '@lingui/macro';

// Instruments fallbacks
const seoFallbackInstrumentTitle = (translatedWildcard: string) =>
    t({
        message: '{translatedWildcard} Samples & Loops - 100% Royalty Free',
        values: { translatedWildcard },
    });
const seoFallbackInstrumentDescription = (lowerCaseWildcard: string) =>
    t({
        message:
            'Download 100% royalty free {lowerCaseWildcard} sample packs from leading labels featuring the best loops, beats and sounds for your music or creative projects. Get access to an expansive library of samples with your LANDR Studio subscription.',
        values: { lowerCaseWildcard },
    });
const seoFallbackInstrumentHeader = (translatedWildcard: string) =>
    t({
        message: '{translatedWildcard} Samples & Loops',
        values: { translatedWildcard },
    });
const seoFallbackInstrumentByline = (lowerCaseWildcard: string) =>
    t({
        message:
            "Explore our collection 100% royalty-free {lowerCaseWildcard} samples & loops. LANDR's {lowerCaseWildcard} sample packs are designed to inspire and elevate your music production, our high-quality sounds & beats provide endless creative possibilities across hundreds of genres.",
        values: { lowerCaseWildcard },
    });

// Genres fallbacks
const seoFallbackGenreTitle = (translatedWildcard: string) =>
    t({
        message: `{translatedWildcard} Samples & Loops - 100% Royalty Free`,
        values: { translatedWildcard },
    });
const seoFallbackGenreDescription = (lowerCaseWildcard: string) =>
    t({
        message: `High quality & royalty free {lowerCaseWildcard} sample packs from the world's top audio sample labels & producers. Find the {lowerCaseWildcard} samples, loops, beats & one-shots you need for your music project at LANDR Samples.`,
        values: { lowerCaseWildcard },
    });
const seoFallbackGenreHeader = (translatedWildcard: string) =>
    t({
        message: '{translatedWildcard} Samples & Loops',
        values: { translatedWildcard },
    });
const seoFallbackGenreByline = (lowerCaseWildcard: string) =>
    t({
        message:
            "Explore our collection 100% royalty-free {lowerCaseWildcard} samples & loops. LANDR's {lowerCaseWildcard} sample packs are designed to inspire and elevate your music production, our high-quality sounds & beats provide endless creative possibilities across hundreds of genres.",
        values: { lowerCaseWildcard },
    });

// Sfxs
const seoFallbackSfxsTitle = (translatedWildcard: string) =>
    t({
        message:
            'Download {translatedWildcard} Samples & Sound Effects - 100% Royalty Free',
        values: { translatedWildcard },
    });
const seoFallbackSfxsDescription = (lowerCaseWildcard: string) =>
    t({
        message:
            'Download royalty free {lowerCaseWildcard} sound effects. Find high quality {lowerCaseWildcard} sounds & samples for your next creative project with LANDR in MP3 or WAV format.',
        values: { lowerCaseWildcard },
    });
const seoFallbackSfxsheader = (translatedWildcard: string) =>
    t({
        message: '{translatedWildcard} Sound Effects',
        values: { translatedWildcard },
    });
const seoFallbackSfxsByline = (lowerCaseWildcard: string) =>
    t({
        message:
            "Explore our collection 100% royalty-free {lowerCaseWildcard} samples & loops. LANDR's {lowerCaseWildcard} sample packs are designed to inspire and elevate your music production, our high-quality sounds & beats provide endless creative possibilities across hundreds of genres.",
        values: { lowerCaseWildcard },
    });

const useGetWildcardSeoFallback = () => {
    const toLocalizedPath = useToLocalizedPath();

    const matchInstruments = useMatch(
        toLocalizedPath(`/${RoutePaths.Instruments}/:wildcard`),
    );
    const matchGenres = useMatch(
        toLocalizedPath(`/${RoutePaths.Genres}/:wildcard`),
    );
    const matchSfxs = useMatch(
        toLocalizedPath(`/${RoutePaths.Sfxs}/:wildcard`),
    );

    const match = matchInstruments || matchGenres || matchSfxs;

    const wildcard = match?.params.wildcard;

    if (!wildcard) {
        return {};
    }

    const translatedWildcard = t({ id: wildcard });
    const lowerCaseWildcard = translatedWildcard.toLowerCase();

    switch (true) {
        case !!matchInstruments?.params.wildcard:
            return {
                title: seoFallbackInstrumentTitle(translatedWildcard),
                description:
                    seoFallbackInstrumentDescription(lowerCaseWildcard),
                header: seoFallbackInstrumentHeader(translatedWildcard),
                byline: seoFallbackInstrumentByline(lowerCaseWildcard),
            };

        case !!matchGenres?.params.wildcard:
            return {
                title: seoFallbackGenreTitle(translatedWildcard),
                description: seoFallbackGenreDescription(lowerCaseWildcard),
                header: seoFallbackGenreHeader(translatedWildcard),
                byline: seoFallbackGenreByline(lowerCaseWildcard),
            };

        case !!matchSfxs?.params.wildcard:
            return {
                title: seoFallbackSfxsTitle(translatedWildcard),
                description: seoFallbackSfxsDescription(lowerCaseWildcard),
                header: seoFallbackSfxsheader(translatedWildcard),
                byline: seoFallbackSfxsByline(lowerCaseWildcard),
            };

        default:
            return {};
    }
};

const useBrowseSeo = (): {
    loading: boolean;
    metaData: BrowseSeoType;
} => {
    const { loading, seoMetaData } = useSeoMetaData({ withWildcard: false });

    const wildcardSeoFallback = useGetWildcardSeoFallback();

    const metaData = {
        ...getStaticSeo().browse,
        ...wildcardSeoFallback,
        ...(seoMetaData?.title && { title: seoMetaData.title }),
        ...(seoMetaData?.description && {
            description: seoMetaData.description,
        }),
        ...(seoMetaData?.header && { header: seoMetaData.header }),
        ...(seoMetaData?.byline && { byline: seoMetaData.byline }),
    };

    return { loading, metaData };
};

export default useBrowseSeo;
