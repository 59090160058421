import { EventIds } from 'constants/eventIds';
import React from 'react';
import { useParams } from 'react-router-dom';
import { t } from '@lingui/macro';
import { useQuery, ApolloError } from '@apollo/client';
import { LabelBySlug } from 'apollo/label/label.gql';
import { getSanitizeSlug } from 'helpers/getSanitizeSlug';
import {
    LabelBySlugQuery,
    LabelBySlugQueryVariables,
} from 'types/generated/graphql';
import { log } from 'utils/log';
import { DynamicMetadata } from '../DynamicMetadata/DynamicMetadata';

export const useLabelDetails = () => {
    const { slug } = useParams<{ slug: string }>();
    const { data } = useQuery<LabelBySlugQuery, LabelBySlugQueryVariables>(
        LabelBySlug,
        {
            variables: { slug: getSanitizeSlug(slug) },
            onError: (error: ApolloError) => {
                log.error(
                    'Failed to get label by slug',
                    EventIds.GetLabelFailed,
                    error,
                    { slug: slug },
                );
            },
        },
    );

    if (!data?.labelBySlug?.name) {
        return {};
    }

    const { name, artworkUrl } = data.labelBySlug;

    return {
        title: t({
            message: '{name} Sample Pack',
            values: { name },
        }),
        description: t({
            message:
                '{name} samples – one shots, loops, phrases, melodies, chords, licks and more — for you to download and use in your productions.',
            values: { name },
        }),
        imageUrl: artworkUrl,
    };
};

export const LabelDetails = () => {
    const { title, description, imageUrl } = useLabelDetails();

    if (!title || !description) {
        return null;
    }

    return (
        <DynamicMetadata
            imageUrl={imageUrl}
            titleFallback={title}
            descriptionFallback={description}
        />
    );
};
