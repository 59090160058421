import { EventIds } from 'constants/eventIds';
import React from 'react';
import { i18n } from 'utils/setupTranslations';
import { useParams } from 'react-router-dom';
import { useQuery, ApolloError } from '@apollo/client';
import { RepackBySlugOrGuid } from 'apollo/repack/repack.gql';
import { getSanitizeSlug } from 'helpers/getSanitizeSlug';
import {
    RepackBySlugOrGuidQuery,
    RepackBySlugOrGuidQueryVariables,
} from 'types/generated/graphql';
import { log } from 'utils/log';
import { DynamicMetadata } from '../DynamicMetadata/DynamicMetadata';
import { getRePackSeoFallback } from './helpers/getRePackSeoFallback';

const hasCode = (item: unknown): item is { code: string } =>
    !!item && typeof (item as { code: string }).code === 'string';

const toLowerCaseLocalizeCode = (item: { code: string }) =>
    i18n._(item.code).toLowerCase();

export const useRePackDetails = () => {
    const { slug } = useParams<{ slug: string }>();
    const { data } = useQuery<
        RepackBySlugOrGuidQuery,
        RepackBySlugOrGuidQueryVariables
    >(RepackBySlugOrGuid, {
        skip: !getSanitizeSlug(slug),
        variables: { repackSlugOrGuid: getSanitizeSlug(slug) },
        onError: (error: ApolloError) => {
            log.error(
                'Failed to get repack by slug',
                EventIds.GetRePackFailed,
                error,
                { slug: slug },
            );
        },
    });

    if (!data?.repack?.name) {
        return {};
    }

    const {
        artworkUrl,
        name,
        brandtype,
        genres,
        instrument: repackInstrument,
    } = data.repack;

    const i18nGenres =
        genres?.filter(hasCode)?.map(toLowerCaseLocalizeCode) ?? [];

    const firstGenre = i18nGenres[0] ?? undefined;
    const secondGenre = i18nGenres[1] ?? undefined;

    const instrument = hasCode(repackInstrument)
        ? toLowerCaseLocalizeCode(repackInstrument)
        : undefined;

    const { title, description } = getRePackSeoFallback({
        brandtypeSlug: brandtype?.slug,
        repackName: name,
        firstGenre,
        secondGenre,
        instrument,
    });

    return {
        title,
        description,
        imageUrl: artworkUrl,
    };
};

export const RePackDetails = () => {
    const { title, description, imageUrl } = useRePackDetails();

    if (!title || !description) {
        return null;
    }

    return (
        <DynamicMetadata
            imageUrl={imageUrl}
            titleFallback={title}
            descriptionFallback={description}
        />
    );
};
